import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
  background-image: linear-gradient(to right, #54006b, #cc123d);
  @media (max-width: 1500px) {
    padding: 50px 30px;
  }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1250px;
    margin: 0 auto;
    /* background: red; */
    @media only screen and (max-width: 768px) {
      display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    }
    @media only screen and (max-width: 820px) {
      display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  @media only screen and (max-width: 768px) {
    margin-left: 5px;
    }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const Link = styled.a`
  color: #fff;
  margin-bottom: 10px;
  font-size: 16px;
  text-decoration: none;
  word-break: break-word;

  &:hover {
      color: #ff9c00;
      transition: 200ms ease-in;
  }
`;

export const Title = styled.p`
  font-size: 23px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 500;
`;