import React from 'react';
import BlogHero from './BlogHero';
import BlogSection from './BlogSection';

function BlogMain() {
  return (
    <div>
        <BlogHero />
        <BlogSection />
    </div>
  )
}

export default BlogMain