import React from 'react'
import styled from 'styled-components';
import BlogCard from './BlogCard';

function BlogSection() {
    const Contain = styled.div`
        padding-top: 100px;
    `
  return (
    <Contain>
        <div className="row m-0">
          <div className="col-md-4 mb-2 d-flex justify-content-center">
          <BlogCard />
          </div>
          <div className="col-md-4 mb-2 d-flex justify-content-center">
          <BlogCard />
          </div>
          <div className="col-md-4 mb-2 d-flex justify-content-center">
          <BlogCard />
          </div>
        </div>
    </Contain>
  )
}

export default BlogSection