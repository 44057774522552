import React from 'react'
import BlogDescription from './BlogDescription'
import BlogInfo from './BlogInfo'
import BlogMore from './BlogMore';

function BlogInfoMain() {
  return (
    <div>
        <BlogInfo />
        <BlogDescription />
        <BlogMore />
    </div>
  )
}

export default BlogInfoMain