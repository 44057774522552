import React from 'react'
import  styled  from 'styled-components';

function Carousel_Card(props) {
    const Contain = styled.div`
        /* background-color: #fff; */
        display: flex;
        /* flex-direction: column; */
        /* justify-content: center;
        align-contents: center; */
        height: 350px;
        margin-bottom: 100px;
        margin-top: 10px;

        
    `
    const HeadCircle = styled.div`
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 50px;
        height: 100px;
        width: 100px;
        margin-left: 200px;
        z-index: 1;
        @media only screen and (max-width: 750px) {
          margin-left: 139px;

    }

    `
    const MainSection = styled.div`
        background-color: #fff;
        height: 360px;
        width: 360px ;
        padding: 10px;
        margin-top: 45px;
        margin-left: -230px;
        border-radius: 30px;
        @media only screen and (max-width: 750px) {
          /* width: 270px ; */
          margin-left: -220px;
          padding: 20px;

    }
    `
    const Text = styled.p`
      margin-top: 35px;
      color: #000;
      padding: 10px;
      font-size: 14px

    `
    const Name = styled.div`
      color: #000;
      margin-top: 60px;
      font-size: 18px;
      font-weight: 600;
      
    `
    const Sub = styled.p`
      color: #333;
      margin-top: -15px;
      font-size: 13px;
      font-weight: 400;
    `
  return (
    <Contain>
            <HeadCircle >
              
            </HeadCircle>
            
            <MainSection>
            <Name className='text-center'>
              <p>{props.StudentName}</p>
              <Sub>{props.Clg}</Sub>
            </Name>
                <Text>{props.innerContent}</Text>
            </MainSection>
    </Contain>
  )
}

export default Carousel_Card