import React from "react";
import styled from "styled-components";

function BlogDescription() {
  const Contain = styled.div`
  padding: 20px 80px;
    /* padding: 15px; */
    @media only screen and (max-width: 921px) {
      padding: 20px;
    }
  `;
  const InnContain = styled.div`
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 20px 80px;
    margin-top: -100px;


    @media only screen and (max-width: 921px) {
      padding: 20px;
      margin-top: -91px;
    }
  `;
  const Head = styled.h3`
      margin-bottom: 20px
  `
  return (
    <Contain>
      <InnContain>
      <div className="text-center">
      <Head>Product Management</Head>
      </div>
        
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repellendus,
          vitae! Impedit ut, placeat voluptatibus odio quas itaque fugit hic
          quis nemo quam id nisi veniam soluta repudiandae atque unde,
          temporibus doloribus amet, magnam ab ea possimus eius provident! Totam
          nesciunt nemo laudantium id voluptatem, assumenda inventore culpa
          tempora ipsa, ea quisquam natus earum reprehenderit! Impedit
          distinctio ipsum sit excepturi tenetur labore, corporis perspiciatis.
          Delectus possimus quis voluptatem assumenda eum impedit magnam
          inventore expedita unde mollitia ut cupiditate fugit rerum molestiae
          sunt pariatur id odit perspiciatis earum harum, deleniti dolor
          laboriosam recusandae ipsam! Optio unde earum eligendi temporibus
          dolorem architecto. Odit?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repellendus,
          vitae! Impedit ut, placeat voluptatibus odio quas itaque fugit hic
          quis nemo quam id nisi veniam soluta repudiandae atque unde,
          temporibus doloribus amet, magnam ab ea possimus eius provident! Totam
          nesciunt nemo laudantium id voluptatem, assumenda inventore culpa
          tempora ipsa, ea quisquam natus earum reprehenderit! Impedit
          distinctio ipsum sit excepturi tenetur labore, corporis perspiciatis.
          Delectus possimus quis voluptatem assumenda eum impedit magnam
          inventore expedita unde mollitia ut cupiditate fugit rerum molestiae
          sunt pariatur id odit perspiciatis earum harum, deleniti dolor
          laboriosam recusandae ipsam! Optio unde earum eligendi temporibus
          dolorem architecto. Odit?
        </p>
      </InnContain>
    </Contain>
  );
}

export default BlogDescription;
