import React from 'react'
import styled from 'styled-components'
import BlogCard from '../BlogCard'

function BlogMore() {
    const Contain = styled.div`
        padding: 30px;
    `
    const Head = styled.h2`
        margin-bottom: 20px;
    `
  return (
    <Contain>
    <div className='text-center'>
    <Head>More Blogs</Head>
    </div>
    <div className="row m-0">
        <div className="col-md-4 mb-2 d-flex justify-content-center">
            <BlogCard />
        </div>
        <div className="col-md-4 mb-2 d-flex justify-content-center">
            <BlogCard />
        </div>
        <div className="col-md-4 mb-2 d-flex justify-content-center">
            <BlogCard />
        </div>
        
    </div>
    </Contain>
  )
}

export default BlogMore